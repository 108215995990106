import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { observer } from "mobx-react";
var AutoCompleteItem = function (props) {
    var item = props.selected ? _jsx("b", { children: props.item }) : props.item;
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    return _jsx("li", { onClick: props.onClick, children: item });
};
var AutoCompleteOptionsBlock = observer(function (props) {
    if (!props.store.autocompleteOptions || !props.store.focused) {
        return null;
    }
    return (_jsx("div", { className: "autocomplete-container", children: _jsx("div", { className: "autocomplete", children: _jsx("ul", { className: "autocomplete-results", children: props.store.autocompleteOptions.map(function (item, index) { return (_jsx(AutoCompleteItem, { item: item, selected: props.store.selectedAutocompleteItem === index, onClick: function () { return props.store.handleOnAutoCompleteItemClick(index); } }, item)); }) }) }) }));
});
var Error = observer(function (props) {
    if (props.store.error.formattedValue) {
        return _jsx("div", { className: "form-error", children: props.store.error.formattedValue });
    }
    return null;
});
export default observer(function (props) {
    useEffect(function () {
        props.store.componentDidMount();
    }, []);
    return (_jsxs("div", { className: props.store.formGroupClassName, children: [_jsxs("div", { className: "form-label-container", children: [_jsx("label", { className: props.store.formLabelClassName, htmlFor: "email", children: props.store.label }), _jsx(Error, { store: props.store })] }), _jsx("div", { className: props.store.formFieldWrapperClassName, children: props.store.inputType === "textarea" ? (_jsx("textarea", { className: "form-field", id: props.store.inputId, name: props.store.name, autoFocus: props.store.autoFocus !== undefined && props.store.autoFocus, disabled: props.store.disabled, onChange: props.store.handleOnChange, onKeyDown: props.store.handleOnKeyDown, ref: props.store.inputRef, onFocus: props.store.handleOnFocus, onBlur: props.store.handleOnFocusOut, placeholder: props.store.placeholder })) : (_jsx("input", { className: "form-field", type: props.store.inputType, id: props.store.inputId, name: props.store.name, autoFocus: props.store.autoFocus !== undefined && props.store.autoFocus, disabled: props.store.disabled, onChange: props.store.handleOnChange, onKeyDown: props.store.handleOnKeyDown, ref: props.store.inputRef, onFocus: props.store.handleOnFocus, onBlur: props.store.handleOnFocusOut, placeholder: props.store.placeholder })) }), _jsx(AutoCompleteOptionsBlock, { store: props.store })] }));
});
