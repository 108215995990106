import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
export var Header = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, children = _a.children;
    return (_jsxs("div", { className: "header", children: [_jsx("div", { className: "title", children: title }), subtitle && _jsx("div", { className: "subtitle", children: subtitle }), children] }));
};
export var ContainerGrid = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, items = _a.items;
    return (_jsxs("div", { className: "container", children: [_jsx(Header, { title: title, subtitle: subtitle }), _jsx("div", { className: "container-grid", children: items.map(function (item) { return (_jsxs("div", { className: "item", children: [_jsx("div", { className: "title", children: item.title }), item.imageSource && (_jsx("div", { className: "image", children: _jsx("img", { src: item.imageSource, alt: item.title }) })), item.text.map(function (paragraph) { return (_jsx("div", { children: paragraph }, paragraph)); })] }, item.title)); }) })] }));
};
export var VerticalSlider = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, items = _a.items;
    var _b = useState(0), active = _b[0], setActive = _b[1];
    var _c = useState(null), clicked = _c[0], setClicked = _c[1];
    var _d = useState(), timer = _d[0], saveTimer = _d[1];
    var spinCarousel = function () {
        setActive(function (prevState) {
            var next = prevState + 1;
            return next === items.length ? 0 : next;
        });
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        recreateTimer();
    };
    var resetTimer = function () {
        saveTimer(function (prevState) {
            clearTimeout(prevState);
            return undefined;
        });
    };
    var recreateTimer = function () {
        return saveTimer(function (prevState) {
            clearTimeout(prevState);
            return setTimeout(spinCarousel, 3000);
        });
    };
    useEffect(function () {
        recreateTimer();
        return function () { return clearTimeout(timer); };
    }, []);
    var onTitleFocusOut = function (index) {
        if (index === clicked) {
            recreateTimer();
            setClicked(null);
        }
    };
    return (_jsxs("div", { className: "container", children: [_jsx(Header, { title: title, subtitle: subtitle }), _jsxs("div", { className: "container-tabs", children: [_jsx("div", { className: "menu-section", children: items.map(function (item, index) { return (_jsx("div", { className: index === active ? "menu-item active" : "menu-item", onClick: function () {
                                setClicked(index);
                                setActive(index);
                                resetTimer();
                            }, onMouseOut: function () { return onTitleFocusOut(index); }, onBlur: function () { return onTitleFocusOut(index); }, children: item.title }, item.title)); }) }), _jsx("div", { className: "content-section", children: items.map(function (item, index) { return (_jsxs("div", { className: index === active ? "content-block active" : "content-block", style: { display: index === active ? "flex" : "none" }, onMouseOver: resetTimer, onFocus: resetTimer, onMouseOut: recreateTimer, onBlur: recreateTimer, children: [item.imageSource && (_jsx("div", { className: "image", children: _jsx("img", { src: item.imageSource, alt: "First", className: "content-image" }) })), _jsx("div", { className: "description", children: item.text.map(function (paragraph) { return (_jsx("div", { children: paragraph }, paragraph)); }) })] }, item.title)); }) })] })] }));
};
