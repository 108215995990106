import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import GoLangIcon from "media/careers-golang-icon.svg";
import ReactIcon from "media/careers-react-icon.svg";
import { ContainerGrid } from "components/Container";
import DocumentTitle from "components/DocumentTitle";
var OpenPositions = [
    {
        title: "React developer",
        imageSource: ReactIcon,
        text: [
            "Proficiency in React.js and its core principles (hooks, context, component lifecycle)",
            "Strong experience with TypeScript and modern JavaScript (ES6+)",
            "Familiarity with state management libraries (MobX, MobX-state-tree, Redux)",
            "Understanding of API integration (REST)",
            "Knowledge of performance optimization and best practices",
            "Experience with unit and integration testing (Jest, React Testing Library)",
            "Familiarity with CI/CD pipelines and Git workflows",
            "Strong problem-solving skills and ability to work in a fast-paced environment",
        ],
    },
    {
        title: "Golang developer",
        imageSource: GoLangIcon,
        text: [
            "Strong proficiency in Go (Golang) and its ecosystem",
            "Experience with concurrency patterns and Goroutines",
            "Familiarity with web frameworks (Gin, Echo, Fiber)",
            "Knowledge of RESTful APIs",
            "Experience with SQL and NoSQL databases (Redis, ElasticSearch, PostgreSQL)",
            "Understanding of cloud platforms (AWS, GCP, or Azure) and containerization (Docker, Kubernetes)",
            "Proficiency in writing scalable and high-performance applications",
            "Experience with monitoring, logging, and debugging tools",
            "Familiarity with CI/CD pipelines and Git workflows",
        ],
    },
];
export default (function () {
    var email = "career@itlook.com";
    var subtitleLink = _jsx("a", { href: "mailto:".concat(email), children: email });
    return (_jsxs(_Fragment, { children: [_jsx(DocumentTitle, { title: "Careers" }), _jsx(ContainerGrid, { title: "Careers", subtitle: _jsxs(_Fragment, { children: [_jsx("p", { children: "Join us in making the infrastructure world a better place!" }), _jsx("p", { children: "We hire remote workers from the EU and the USA." }), _jsxs("p", { children: ["Contact us at ", subtitleLink] })] }), items: OpenPositions })] }));
});
