import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// NOTE(andreykurilin): the name starts with underscore to separate module from pages
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import ContactUsForm from "components/ContactUsForm";
import NotificationsContainer from "components/Notifications";
import Store from "stores/Store";
var HeaderNavigationItem = function (_a) {
    var title = _a.title, url = _a.url, _b = _a.className, className = _b === void 0 ? "" : _b;
    var location = useLocation();
    var _c = useState(false), active = _c[0], setIsActive = _c[1];
    useEffect(function () {
        setIsActive(location.pathname.startsWith(url));
    }, [location.pathname]);
    return (_jsx(Link, { to: url, className: active ? "".concat(className, " active").trimStart() : className || undefined, children: _jsx("span", { children: title }) }));
};
var HeaderNavigationItems = [
    { title: "About", url: "/about" },
    { title: "Pricing", url: "/pricing" },
    { title: "Careers", url: "/careers" },
];
var NavigationBar = function () {
    var _a = useState(false), menuIsToggled = _a[0], setMenuIsToggled = _a[1];
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "menu-container", children: _jsxs("div", { className: menuIsToggled ? "menu open" : "menu", children: [HeaderNavigationItems.map(function (_a) {
                            var title = _a.title, url = _a.url;
                            return (_jsx(HeaderNavigationItem, { title: title, url: url }, title));
                        }), menuIsToggled && _jsx(HeaderNavigationItem, { title: "Request beta", url: "/request-beta" }, "request-beta")] }) }), _jsxs("div", { className: "actions-container", children: [_jsx("button", { type: "button", className: "btn-transparent-small mobile-contact", onClick: Store.ContactUsModal.showModal, children: _jsx("span", { children: "Contact Us" }) }), _jsx("div", { className: "mobile-sign-in", children: _jsx(Link, { to: "/request-beta" }) }), _jsx(HeaderNavigationItem, { title: "Request beta", className: "sign-in", url: "/request-beta" }), _jsxs("div", { className: menuIsToggled ? "hamburger active" : "hamburger", onClick: function () { return setMenuIsToggled(function (value) { return !value; }); }, children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })] })] }));
};
export default (function (_a) {
    var children = _a.children, cleanLayout = _a.cleanLayout, reloadPageOnLogoClick = _a.reloadPageOnLogoClick;
    return (_jsxs(_Fragment, { children: [!cleanLayout && _jsx(ContactUsForm, {}), _jsxs("div", { className: "wrapper", children: [_jsx(NotificationsContainer, {}), _jsxs("nav", { className: "global-header", children: [_jsx("div", { className: "logo-container", children: _jsx("div", { className: "logo", children: _jsx(Link, { to: "/", reloadDocument: reloadPageOnLogoClick }) }) }), !cleanLayout && _jsx(NavigationBar, {})] }), _jsx("div", { className: "main", children: children || _jsx(Outlet, {}) }), _jsxs("div", { className: "footer", children: [_jsxs("div", { className: "social", children: [_jsx("a", { className: "linkedin", href: "https://linkedin.com/company/itlook", "aria-label": "LinkedIn" }), _jsx("a", { className: "crunchbase", href: "https://www.crunchbase.com/organization/itlook", "aria-label": "Crunchbase" }), _jsx("a", { className: "youtube", href: "https://www.youtube.com/@ITLook-Inc", "aria-label": "YouTube" })] }), _jsx("div", { className: "footer-menu", children: HeaderNavigationItems.map(function (_a) {
                                    var title = _a.title, url = _a.url;
                                    return (_jsx(Link, { to: url, children: title }, title));
                                }) }), _jsxs("div", { className: "footer-address", children: [_jsx("div", { children: "\u00A9 2025 ITLook. All rights reserved." }), _jsxs("div", { children: ["Made with ", _jsx("span", { className: "text-danger", children: "\u2665" }), " by passionate engineers and managers."] })] })] })] })] }));
});
