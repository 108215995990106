import React from "react";
import { types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
var KeyCodes = {
    TAB: 9,
    ENTER: 13,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
};
var requiredValidator = function (value, self) {
    if (value === "") {
        var label = self.label ? self.label : "Value";
        return "".concat(label, " is required field.");
    }
    return false;
};
export var minLengthValidator = function (minLength) {
    var validator = function (value) {
        if (value.length < minLength) {
            return "The length should be bigger than ".concat(minLength);
        }
        return false;
    };
    return validator;
};
export var maxLengthValidator = function (maxLength) {
    var validator = function (value) {
        if (value.length > maxLength) {
            return "The length should be less than ".concat(maxLength);
        }
        return false;
    };
    return validator;
};
export var ErrorStore = types
    .model("ErrorStore", {
    value: "",
})
    .actions(function (self) { return ({
    set: function (value) {
        self.value = value;
    },
    reset: function () {
        self.value = "";
    },
}); })
    .views(function (self) { return ({
    get formattedValue() {
        return self.value;
    },
}); });
export var NoDisplayError = ErrorStore.named("NoDisplayError").views(function () { return ({
    get formattedValue() {
        return undefined;
    },
}); });
var InputStore = types
    .model("InputStore", {
    /* Define here only customizable properties */
    value: "",
    error: types.union(ErrorStore, NoDisplayError),
    // label configuration
    label: types.maybe(types.string),
    required: false,
    inputId: types.optional(types.string, function () { return uuidv4(); }),
    // input placeholder
    placeholder: types.maybe(types.string),
    // type of input
    inputType: "text",
    // name of the control, which is submitted with the form data.
    name: types.maybe(types.string),
    // additional components that should be places after <input /> component
    autocompleteOptions: types.array(types.string),
    // whether the browser should focus on input component
    autoFocus: types.maybe(types.boolean),
    // whether user can change input field
    disabled: false,
    // disables modifications of disabled flag
    ignoreSetDisabledCalls: false,
    // whether trigger blur event (release focus from input) on EnterPress
    releaseFocusOnEnterPress: types.maybeNull(types.boolean),
    // time to wait before triggering onChange handler
    onChangeHandlingDelay: 0,
    // inner fields that user/developer (the person who uses this store) should not interact with
    delayedValue: types.maybeNull(types.string),
    focused: false,
    selectedAutocompleteItem: -1,
})
    .volatile(function () { return ({
    inputRef: undefined,
    timer: undefined,
    validators: [],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange: function (value, e) { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onEnterPress: function (value) { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFocus: function (e) { },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFocusOut: function (e) { },
}); })
    .views(function (self) { return ({
    _defaultIsDone: function () {
        return ((self.value || !self.required) &&
            (self.timer === undefined || self.onChangeHandlingDelay === 0) &&
            !self.error.value);
    },
    isDone: function () {
        return this._defaultIsDone();
    },
    get formGroupClassName() {
        return self.error.formattedValue ? "form-group has-error" : "form-group";
    },
    get formLabelClassName() {
        return self.required ? "form-label required" : "form-label";
    },
    get formFieldWrapperClassName() {
        return self.disabled ? "form-field-wrapper disabled" : "form-field-wrapper";
    },
}); })
    .actions(function (self) { return ({
    registerOnChangeHandler: function (func) {
        self.onChange = func;
    },
    registerOnEnterPressHandler: function (func) {
        self.onEnterPress = func;
    },
    registerOnFocusHandler: function (func) {
        self.onFocus = func;
    },
    registerOnFocusOutHandler: function (func) {
        self.onFocusOut = func;
    },
    addInputValidator: function (func) {
        self.validators.push(func);
    },
    setValue: function (value) {
        self.value = value;
        // inputRef.current == null when the component is not attached yet
        if (self.inputRef && self.inputRef.current !== null) {
            if (self.timer) {
                clearTimeout(self.timer);
            }
            self.inputRef.current.value = value;
        }
    },
    setError: function (errorMessage, validatedValue) {
        if (validatedValue === undefined || self.value === validatedValue) {
            self.error.set(errorMessage || "");
        }
    },
    setInputRef: function (value) {
        self.inputRef = value;
    },
    setDisabled: function (value) {
        if (self.ignoreSetDisabledCalls) {
            return;
        }
        self.disabled = value;
    },
    validate: function () {
        var _this = this;
        var currentValue = self.value;
        self.error.reset();
        self.validators.forEach(function (validator) {
            // NOTE(andreykurilin): Should we collect all validation error
            //    or stop on first one (current implementation)?
            if (!self.error.value) {
                var errorMessageOrPromise = validator(currentValue, self);
                if (errorMessageOrPromise && typeof errorMessageOrPromise === "string") {
                    _this.setError(errorMessageOrPromise, currentValue);
                }
                else {
                    Promise.resolve(errorMessageOrPromise).then(function (errorMessage) {
                        if (errorMessage) {
                            _this.setError(errorMessage, currentValue);
                        }
                    });
                }
            }
        });
    },
    handleOnChangeDelayed: function (e) {
        self.value = e.target.value;
        this.validate();
        self.onChange(e.target.value, e);
        self.timer = undefined;
    },
    handleOnChange: function (e) {
        var _this = this;
        // if user entered something, selected item from suggestion should be reset
        self.selectedAutocompleteItem = -1;
        self.delayedValue = e.target.value;
        if (self.timer !== undefined) {
            clearTimeout(self.timer);
        }
        self.timer = setTimeout(function () { return _this.handleOnChangeDelayed(e); }, self.onChangeHandlingDelay);
    },
    handleOnFocus: function (e) {
        self.focused = true;
        self.onFocus(e);
    },
    handleOnFocusOutDelayed: function () {
        self.focused = false;
    },
    handleOnFocusOut: function (e) {
        if (self.autocompleteOptions) {
            // onFocusOut/onBlur event is triggered before onClick event. If we do not give some delay before changing
            // 'focused' state, Suggestion window disappear before possible onClick event fires on Suggestion item.
            setTimeout(this.handleOnFocusOutDelayed, 100);
        }
        else {
            self.focused = false;
        }
        self.onFocusOut(e);
    },
    handleOnAutoCompleteItemClick: function (itemIndex) {
        // reset any delayed change
        if (self.timer !== undefined) {
            clearTimeout(self.timer);
            self.timer = undefined;
        }
        // take selected item and put it everywhere
        var selectedItem = self.autocompleteOptions[itemIndex];
        this.setValue(selectedItem);
        self.onChange(selectedItem);
        self.selectedAutocompleteItem = -1;
        if (self.inputRef && self.inputRef.current) {
            self.inputRef.current.blur();
        }
    },
    handleOnKeyDown: function (e) {
        var keycode = e.keyCode || e.which;
        if (keycode === KeyCodes.ENTER) {
            e.preventDefault();
            e.stopPropagation();
            if (self.timer !== undefined) {
                clearTimeout(self.timer);
                self.onChange(self.delayedValue || "");
            }
            if (self.selectedAutocompleteItem !== -1) {
                this.handleOnAutoCompleteItemClick(self.selectedAutocompleteItem);
            }
            else {
                self.onEnterPress(self.delayedValue || "");
                if (self.releaseFocusOnEnterPress && self.inputRef && self.inputRef.current) {
                    self.inputRef.current.blur();
                }
            }
        }
        if (self.autocompleteOptions) {
            if (keycode === KeyCodes.ARROW_DOWN) {
                self.selectedAutocompleteItem = (self.selectedAutocompleteItem + 1) % self.autocompleteOptions.length;
            }
            else if (keycode === KeyCodes.ARROW_UP) {
                if (self.selectedAutocompleteItem === -1) {
                    // select last item
                    self.selectedAutocompleteItem = self.autocompleteOptions.length - 1;
                }
                else {
                    self.selectedAutocompleteItem -= 1;
                }
            }
            else if ((keycode === KeyCodes.TAB || keycode === KeyCodes.ARROW_RIGHT) &&
                self.selectedAutocompleteItem !== -1) {
                this.handleOnAutoCompleteItemClick(self.selectedAutocompleteItem);
            }
        }
    },
    afterCreate: function () {
        self.inputRef = React.createRef();
        if (self.required) {
            this.addInputValidator(function (curValue) { return requiredValidator(curValue, self); });
        }
    },
    componentDidMount: function () {
        if (self.value) {
            this.setValue(self.value);
        }
    },
}); });
export default InputStore;
