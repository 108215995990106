import { getParent, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
import CheckBoxGroupStore from "components/CheckBoxGroupS";
import EmailInputStore from "components/input/EmailInputS";
import InputStore, { ErrorStore, maxLengthValidator, NoDisplayError } from "components/input/InputS";
import Notifications from "stores/Notifications";
var ContactUsForm = types
    .model("ContactUsForm", {
    turnstileToken: types.maybeNull(types.string),
    uid: types.optional(types.string, function () { return uuidv4(); }),
    email: types.optional(EmailInputStore, function () {
        return EmailInputStore.create({
            placeholder: "your@email",
            required: true,
            error: ErrorStore.create({}),
        });
    }),
    fullName: types.optional(InputStore, function () {
        return InputStore.create({
            label: "Full name",
            placeholder: "A name we can refer to in our response.",
            required: false,
            error: NoDisplayError.create({}),
        });
    }),
    message: types.optional(InputStore, function () {
        return InputStore.create({
            inputType: "textarea",
            label: "Message",
            placeholder: "Any information that would help us assist you better.",
            required: false,
            error: ErrorStore.create({}),
        });
    }),
    company: types.optional(InputStore, function () {
        return InputStore.create({
            label: "Company",
            placeholder: "Helps us understand your context better.",
            required: false,
            error: NoDisplayError.create({}),
        });
    }),
    jobTitle: types.optional(InputStore, function () {
        return InputStore.create({
            label: "Job Title",
            placeholder: "Your role (e.g., DevOps Engineer, IT Manager)",
            required: false,
            error: NoDisplayError.create({}),
        });
    }),
    topics: types.optional(CheckBoxGroupStore, function () {
        return CheckBoxGroupStore.create({
            label: "Topic or Inquiry Type",
            items: [
                { label: "General Inquiry" },
                { label: "Product Demo" },
                { label: "Pricing" },
                { label: "Support" },
                { label: "Partnership" },
            ],
        });
    }),
})
    .views(function (self) { return ({
    get ready() {
        return Boolean(self.email.isDone() && self.message.isDone() && self.turnstileToken !== null);
    },
    get data() {
        var topic = self.topics.items.filter(function (item) { return item.checked; }).map(function (i) { return i.label; });
        var data = {
            email: self.email.value,
            full_name: self.fullName.value,
            message: self.message.value,
            company: self.company.value,
            job_title: self.jobTitle.value,
            topics: topic.length === 0 ? false : topic,
        };
        return Object.fromEntries(Object.entries(data).filter(function (_a) {
            var value = _a[1];
            return value;
        }));
    },
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        self.message.addInputValidator(maxLengthValidator(2048));
    },
    setTurnstileToken: function (value) {
        if (value || self.turnstileToken) {
            self.turnstileToken = value || null;
        }
    },
}); });
export default types
    .model("ContactUsModal", {
    active: false,
    form: types.optional(ContactUsForm, function () { return ContactUsForm.create({}); }),
    processing: false,
})
    .views(function (self) { return ({
    get className() {
        return self.active ? "modal-overlay active" : "modal-overlay";
    },
    get api() {
        var api = getParent(self).api;
        return api;
    },
}); })
    .actions(function (self) { return ({
    setProcessing: function (value) {
        self.processing = value;
        if (self.processing) {
            self.form.email.setDisabled(value);
            self.form.fullName.setDisabled(value);
            self.form.message.setDisabled(value);
            self.form.company.setDisabled(value);
            self.form.jobTitle.setDisabled(value);
            self.form.topics.setDisabled(value);
        }
    },
    showModal: function () {
        self.active = true;
        document.body.style.overflow = "hidden";
    },
    closeModal: function () {
        self.active = false;
        document.body.style.overflow = "";
        self.form = ContactUsForm.create({});
        // recreate form
        this.setProcessing(false);
    },
    submit: function () {
        var _this = this;
        if (!self.form.ready) {
            return;
        }
        this.setProcessing(true);
        self.api
            .post("/api/v1/account/contact-us", self.form.data, { params: { cf_turnstile: self.form.turnstileToken } })
            .then(function () {
            _this.closeModal();
            Notifications.success("Thank you! Your message has been sent. We'll get back to you soon.");
        })
            .catch(function () {
            _this.closeModal();
            Notifications.error();
        })
            .finally(function () {
            _this.setProcessing(false);
        });
    },
    afterCreate: function () {
        self.form.email.registerOnEnterPressHandler(this.submit);
        self.form.fullName.registerOnEnterPressHandler(this.submit);
        self.form.company.registerOnEnterPressHandler(this.submit);
        self.form.message.registerOnEnterPressHandler(this.submit);
        self.form.jobTitle.registerOnEnterPressHandler(this.submit);
    },
}); });
