import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { observer } from "mobx-react";
import { Turnstile } from "@marsidev/react-turnstile";
import CheckBoxGroup from "components/CheckBoxGroup";
import Input from "components/input/Input";
import Store from "stores/Store";
var SubmitButton = observer(function (props) { return (_jsx("button", { type: "submit", className: "btn-primary", onClick: function (e) {
        e.stopPropagation();
        e.preventDefault();
        props.store.submit();
    }, disabled: !props.store.form.ready && props.store.processing, children: "Submit" })); });
var Content = observer(function () {
    var store = useState(Store.ContactUsModal)[0];
    return (_jsxs("div", { className: "modal-content", children: [_jsxs("div", { className: "modal-header", children: [_jsx("h3", { className: "modal-title", children: "Contact Us" }), _jsx("button", { className: "modal-close", type: "button", onClick: store.closeModal })] }), _jsx("div", { className: "modal-body", children: _jsxs("form", { className: "form-container", children: [_jsx(Input, { store: store.form.email }, "email"), _jsx(Input, { store: store.form.fullName }, "fullName"), _jsx(Input, { store: store.form.company }, "company"), _jsx(Input, { store: store.form.jobTitle }, "jobTitle"), _jsx(Input, { store: store.form.message }, "message"), _jsx(CheckBoxGroup, { store: store.form.topics }), store.active && (_jsx(Turnstile, { siteKey: process.env.TURNSTILE_SITE_KEY, onSuccess: store.form.setTurnstileToken, options: { size: "flexible", theme: "dark", action: "contact-us" } })), _jsxs("div", { className: "form-actions", children: [_jsx("button", { type: "button", className: "btn-secondary", onClick: store.closeModal, children: "Cancel" }), _jsx(SubmitButton, { store: store })] })] }) }, store.form.uid)] }));
});
export default observer(function () {
    var store = useState(Store.ContactUsModal)[0];
    return (_jsx("div", { className: store.className, children: _jsx(Content, {}) }));
});
