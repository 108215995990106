import { types } from "mobx-state-tree";
import CheckBoxSwitcherS from "components/CheckBoxSwitcherS";
var CheckBoxGroupS = types
    .model("CheckBoxGroupS", {
    label: types.string,
    items: types.array(CheckBoxSwitcherS),
})
    .volatile(function () { return ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    externalOnChange: function (pos, label, value) { },
    inputRef: null,
}); })
    .actions(function (self) { return ({
    registerOnChange: function (handler) {
        self.externalOnChange = handler;
        self.items.forEach(function (item, pos) {
            item.registerOnChange(function (value) { return self.externalOnChange(pos, item.label, value); });
        });
    },
    setDisabled: function (value) {
        self.items.forEach(function (item) {
            item.setDisabled(value);
        });
    },
    componentDidMount: function () {
        self.items.forEach(function (item) { return item.componentDidMount(); });
    },
}); });
export default CheckBoxGroupS;
