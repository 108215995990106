import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { observer } from "mobx-react";
import ExpPage from "experiments/ExpFullPage";
import { InternalServerError, NotFoundError } from "pages/_Errors";
import BaseLayout from "pages/_Layout";
import AboutUs from "pages/AboutUs";
import SignIn from "pages/Auth";
import Careers from "pages/Careers";
import Main from "pages/Main";
import Plans from "pages/Plans";
import Billing from "pages/profile/Billing";
import ProfilePageLayout from "pages/profile/pageLayout";
import Security from "pages/profile/Security";
import RequestBeta, { RequestBetaCallback } from "pages/RequestBeta";
import Store from "stores/Store";
var AuthenticatedRoute = function (_a) {
    var children = _a.children;
    var profile = useState(Store.Profile)[0];
    if (!profile.token) {
        return _jsx(NotFoundError, {});
    }
    return children;
};
export default observer(function () {
    var store = useState(Store)[0];
    useEffect(function () {
        Store.onStartup();
    }, []);
    if (store.internalError) {
        // NOTE(andreykurilin): Although there is a single route, we still need to initialize RouterProvider to make all
        //    links work as they rely on the Link component from react-router-dom which needs context to be in place.
        return _jsx(RouterProvider, { router: createBrowserRouter([{ path: "*", element: _jsx(InternalServerError, {}) }]) });
    }
    var routes = [];
    if (process.env.NODE_ENV === "development" || !process.env.NODE_ENV) {
        routes.push({ path: "/exp", element: _jsx(ExpPage, {}) }, { path: "/exp/:page", element: _jsx(ExpPage, {}) });
    }
    routes.push({
        element: _jsx(BaseLayout, {}),
        errorElement: _jsx(NotFoundError, {}),
        children: [
            {
                path: "/",
                element: _jsx(Main, {}),
            },
            {
                path: "about",
                element: _jsx(AboutUs, {}),
            },
            {
                path: "careers",
                element: _jsx(Careers, {}),
            },
            {
                path: "plans",
                element: _jsx(Plans, {}),
            },
            {
                path: "signin",
                element: _jsx(SignIn, {}),
            },
            {
                path: "request-beta",
                element: _jsx(RequestBeta, {}),
            },
            {
                path: "request-beta-callback/:provider",
                element: _jsx(RequestBetaCallback, {}),
            },
            {
                element: (_jsx(AuthenticatedRoute, { children: _jsx(ProfilePageLayout, {}) })),
                children: [
                    {
                        path: "billing",
                        element: _jsx(Billing, {}),
                    },
                    {
                        path: "security",
                        element: _jsx(Security, {}),
                    },
                ],
            },
        ],
    });
    return _jsx(RouterProvider, { router: createBrowserRouter(routes) });
});
