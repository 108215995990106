var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DangerIcon from "media/notification-danger-icon.svg";
import InfoIcon from "media/notification-info-icon.svg";
import SuccessIcon from "media/notification-success-icon.svg";
import WarningIcon from "media/notification-warning-icon.svg";
import Notifications, { MessageType } from "stores/Notifications";
var DEFAULT_EXPIRATION_TIME = 20 * 1000;
var TypeToIcon = (_a = {},
    _a[MessageType.info] = InfoIcon,
    _a[MessageType.success] = SuccessIcon,
    _a[MessageType.error] = DangerIcon,
    _a[MessageType.warning] = WarningIcon,
    _a);
var Notification = observer(function (_a) {
    var notification = _a.notification;
    var _b = useState(), timer = _b[0], setTimer = _b[1];
    var stopTimer = function () {
        if (timer) {
            clearTimeout(timer);
            setTimer(undefined);
        }
    };
    var onAnimationEnd = function () {
        if (notification.dying) {
            stopTimer();
            Notifications.deleteItem(notification.id);
        }
    };
    var setDying = function () {
        stopTimer();
        notification.setDying();
    };
    var initTimer = function () {
        if (timer === undefined) {
            setTimer(setTimeout(setDying, DEFAULT_EXPIRATION_TIME));
        }
    };
    useEffect(function () {
        initTimer();
        return stopTimer;
    }, []);
    return (_jsxs("div", { className: "notification ".concat(notification.className), onMouseOver: stopTimer, onFocus: stopTimer, onMouseOut: initTimer, onBlur: initTimer, onAnimationEnd: onAnimationEnd, children: [_jsx("div", { className: "notification-icon", children: _jsx("img", { src: TypeToIcon[notification.type], alt: notification.type }) }), _jsxs("div", { className: "notification-content", children: [notification.title && _jsx("div", { className: "notification-title", children: notification.title }), notification.messages.map(function (msg, index) { return (
                    // Notification obj does not have methods to change the message,
                    //  so index of lines can be treated as a constant identifier
                    //
                    // eslint-disable-next-line react/no-array-index-key
                    _jsx("div", { className: "notification-message", children: msg }, index)); })] }), _jsx("button", { className: "notification-close", type: "button", "aria-label": "Close notification", onClick: setDying })] }));
});
export default observer(function () {
    var notifications = useState(Notifications)[0];
    return (_jsx("div", { className: "notifications-container", children: notifications.items.map(function (n) { return (_jsx(Notification, { notification: n }, n.id)); }) }));
});
