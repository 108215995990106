import { types } from "mobx-state-tree";
var Settings = types
    .model("Settings", {
    signedCookies: false,
    signInSignUpModal: false,
})
    .actions(function (self) { return ({
    afterCreate: function () {
        self.signedCookies = localStorage.getItem("signedCookies") !== null;
    },
    acceptCookies: function () {
        self.signedCookies = true;
        localStorage.setItem("signedCookies", "true");
    },
    setSignInSignUpModal: function (value) {
        self.signInSignUpModal = value;
    },
}); });
export default Settings;
