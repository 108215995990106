import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ContactUsModal from "stores/ContactUs";
import Profile from "stores/Profile";
import Settings from "stores/Settings";
export var Store = types
    .model("Store", {
    internalError: false,
    ContactUsModal: types.optional(ContactUsModal, function () { return ContactUsModal.create({}); }),
    Profile: types.optional(Profile, function () { return Profile.create({}); }),
    Settings: types.optional(Settings, function () { return Settings.create({}); }),
})
    .actions(function (self) {
    var initialState = {};
    return {
        afterCreate: function () {
            // we save initial state to reset it as a part of logout
            initialState = getSnapshot(self);
        },
        reset: function () {
            localStorage.clear();
            applySnapshot(self, initialState);
        },
        setInternalError: function () {
            self.internalError = true;
        },
        onStartup: function () {
            self.Profile.onStartup();
        },
    };
})
    .views(function (self) { return ({
    get api() {
        var headers = {
            "Content-Type": "application/json",
        };
        if (self.Profile.token) {
            headers.Authorization = "Bearer ".concat(self.Profile.token);
        }
        var api = axios.create({
            headers: headers,
            timeout: 5000,
            transformResponse: [
                function (data) {
                    try {
                        var body = JSON.parse(data);
                        if (typeof body !== "object" || Array.isArray(body) || body === null) {
                            self.setInternalError();
                        }
                        return body;
                    }
                    catch (_a) {
                        self.setInternalError();
                    }
                    return data;
                },
            ],
        });
        api.interceptors.request.use(function (request) {
            var requestID = uuidv4();
            request.headers.set("ITLook-Request-ID", requestID);
            // eslint-disable-next-line no-console
            console.debug("Request '".concat(request.method, " ").concat(request.url, "' [req-id=").concat(requestID, "]"));
            return request;
        });
        api.interceptors.response.use(function (response) { return response; }, function (error) {
            if (error.response && error.response.status >= 500) {
                self.setInternalError();
            }
            return Promise.reject(error);
        });
        return api;
    },
}); });
export default Store.create({});
