import { cast, types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
export var MessageType = {
    info: "default",
    success: "success",
    warning: "warning",
    error: "danger",
};
var Notification = types
    .model("Notification", {
    id: types.string,
    title: types.maybeNull(types.string),
    messages: types.array(types.string),
    type: types.enumeration(Object.values(MessageType)),
    dying: false,
})
    .views(function (self) { return ({
    get className() {
        var className = "notification-".concat(self.type);
        if (self.dying) {
            className += " removing";
        }
        return className;
    },
}); })
    .actions(function (self) { return ({
    setDying: function () {
        self.dying = true;
    },
}); });
var defaultErrorMessage = "Oops, something went wrong. Our best engineers is already working on fixing this.";
var Notifications = types
    .model({
    items: types.array(Notification),
})
    .actions(function (self) { return ({
    notify: function (messages, title, type) {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }
        self.items.push(Notification.create({ messages: messages, type: type, id: uuidv4(), title: title || null }));
    },
    info: function (messages, title) {
        this.notify(messages, title, MessageType.info);
    },
    success: function (messages, title) {
        this.notify(messages, title, MessageType.success);
    },
    warn: function (messages, title) {
        this.notify(messages, title, MessageType.warning);
    },
    error: function (messages, title) {
        this.notify(messages || defaultErrorMessage, title, MessageType.error);
    },
    deleteItem: function (notificationID) {
        self.items = cast(self.items.filter(function (n) { return n.id !== notificationID; }));
    },
}); });
export default Notifications.create({});
