import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { observer } from "mobx-react";
var CheckBox = observer(function (_a) {
    var item = _a.item;
    return (_jsxs("label", { className: "form-check", children: [_jsx("input", { id: item.itemID, type: "checkbox", name: item.label, onClick: item.handleOnChange, 
                // add dummy onChange handler to suppress warning at browser console
                onChange: function () { }, disabled: item.disabled }), _jsx("span", { children: item.label })] }));
});
export default (function (_a) {
    var store = _a.store;
    useEffect(function () {
        store.componentDidMount();
    }, []);
    return (_jsxs("div", { className: "form-group", children: [_jsx("div", { className: "form-label-container", children: _jsx("label", { className: "form-label", children: store.label }) }), store.items.map(function (item) { return (_jsx(CheckBox, { item: item }, item.label)); })] }));
});
