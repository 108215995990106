import { useEffect, useState } from "react";
export default (function (_a) {
    var title = _a.title, _b = _a.noSuffix, noSuffix = _b === void 0 ? false : _b;
    var _c = useState(undefined), previousTitle = _c[0], setPreviousTitle = _c[1];
    useEffect(function () {
        setPreviousTitle(document.title);
        document.title = noSuffix ? title : "".concat(title, " | ITLook Inc");
        return function () {
            if (previousTitle !== undefined) {
                document.title = previousTitle;
            }
        };
    }, []);
    return null;
});
