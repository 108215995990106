import React from "react";
import { types } from "mobx-state-tree";
import { v4 as uuidv4 } from "uuid";
var CheckBoxSwitcher = types
    .model("CheckBoxSwitcher", {
    itemID: types.maybeNull(types.string),
    label: types.string,
    checked: false,
    disabled: false,
})
    .volatile(function () { return ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    externalOnChange: function (value) { },
    inputRef: null,
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        if (self.itemID === null) {
            self.itemID = uuidv4();
        }
        self.inputRef = React.createRef();
    },
    componentDidMount: function () {
        this.setChecked(self.checked);
    },
    registerOnChange: function (handler) {
        self.externalOnChange = handler;
    },
    handleOnChange: function () {
        self.checked = !self.checked;
        self.externalOnChange(self.checked);
    },
    setDisabled: function (value) {
        self.disabled = value;
    },
    setChecked: function (value) {
        self.checked = value;
        if (self.inputRef && self.inputRef.current) {
            self.inputRef.current.checked = value;
        }
    },
}); });
export default CheckBoxSwitcher;
