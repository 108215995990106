import { getParentOfType, types } from "mobx-state-tree";
import { jwtDecode } from "jwt-decode";
import CheckBoxSwitcherS from "components/CheckBoxSwitcherS";
import Notifications from "stores/Notifications";
import { Store as StoreS } from "stores/Store";
var _TokenKey = "access-token";
var NotYetRegisteredUser = types
    .model("NotYetRegisteredUser", {
    token: types.string,
    redirectUrl: "/",
    termsAndConditionsCheckBox: types.optional(CheckBoxSwitcherS, function () {
        return CheckBoxSwitcherS.create({
            label: "Agree terms and conditions",
        });
    }),
    cookiesCheckBox: types.optional(CheckBoxSwitcherS, function () {
        return CheckBoxSwitcherS.create({
            label: "Agree to the use cookies",
        });
    }),
    processing: false,
})
    .views(function (self) { return ({
    get everythingIsReadyForSignUp() {
        return self.termsAndConditionsCheckBox.checked;
    },
}); })
    .actions(function (self) { return ({
    signUp: function (e) {
        e.stopPropagation();
        e.preventDefault();
        var api = getParentOfType(self, StoreS).api;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        var profileStore = getParentOfType(self, Profile);
        api
            .post("/api/v1/account/sign-up", {
            cookies: self.cookiesCheckBox.checked,
        }, {
            headers: {
                Authorization: "Bearer ".concat(self.token),
            },
        })
            .then(function (response) {
            profileStore.setToken(response.data.data.token);
            profileStore.loadProfile();
        })
            .catch(function () { });
    },
    setProcessing: function (value) {
        self.processing = value;
        self.termsAndConditionsCheckBox.setDisabled(value);
        self.cookiesCheckBox.setDisabled(value);
    },
}); });
var OAuth2 = types.model("OAuth2").actions(function (self) { return ({
    getUrl: function (provider) {
        if (process.env.NODE_ENV === "development") {
            provider = "".concat(provider, "-dev");
        }
        return new Promise(function (resolve) {
            var api = getParentOfType(self, StoreS).api;
            api
                .get("/api/v1/auth/oauth2/".concat(provider))
                .then(function (response) {
                resolve(response.data.data);
            })
                .catch(function () { });
        });
    },
    authenticateWithOAuth2Code: function (provider, code) {
        var api = getParentOfType(self, StoreS).api;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        var profileStore = getParentOfType(self, Profile);
        api
            .get("/api/v1/auth/oauth2/".concat(provider, "/callback"), { params: { code: code } })
            .then(function (response) {
            if (jwtDecode(response.data.data.token).typ === "new-user") {
                profileStore.setNotYetRegisteredUser(response.data.data.token, response.data.data.redirect_url || "/");
            }
            else {
                profileStore.setToken(response.data.data.token);
                profileStore.loadProfile();
            }
        })
            .catch(function () { });
    },
}); });
var Profile = types
    .model("Profile", {
    token: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    processing: true,
    oauth2: types.optional(OAuth2, function () { return OAuth2.create(); }),
    newUser: types.maybeNull(NotYetRegisteredUser),
})
    .views(function (self) { return ({
    get authenticated() {
        return self.name !== null;
    },
    get settings() {
        var parent = getParentOfType(self, StoreS);
        return parent.Settings;
    },
}); })
    .actions(function (self) { return ({
    onStartup: function () {
        var token = localStorage.getItem(_TokenKey);
        this.setToken(token);
        if (token) {
            this.loadProfile();
        }
    },
    loadProfile: function () {
        var _this = this;
        this.setProcessing(true);
        var api = getParentOfType(self, StoreS).api;
        api
            .get("/api/v1/auth/me")
            .then(function (response) {
            _this.saveProfileData(response.data.data);
        })
            .catch(function () { })
            .finally(function () { return _this.setProcessing(false); });
    },
    setToken: function (value) {
        self.token = value;
        if (self.settings.signedCookies) {
            if (value === null) {
                localStorage.removeItem(_TokenKey);
            }
            else {
                localStorage.setItem(_TokenKey, value);
            }
        }
    },
    setProcessing: function (value) {
        self.processing = value;
    },
    setNotYetRegisteredUser: function (token, redirectUrl) {
        if (redirectUrl === void 0) { redirectUrl = "/"; }
        if (token === null) {
            self.newUser = null;
        }
        else {
            self.newUser = NotYetRegisteredUser.create({ token: token, redirectUrl: redirectUrl });
            if (self.settings.signedCookies) {
                self.newUser.cookiesCheckBox.setChecked(true);
            }
        }
    },
    saveProfileData: function (_a) {
        var name = _a.name;
        self.name = name;
    },
    logout: function (navigate) {
        var _this = this;
        this.setProcessing(true);
        var api = getParentOfType(self, StoreS).api;
        api
            .post("/api/v1/auth/logout")
            .then()
            .catch(function () { })
            .finally(function () {
            _this.setProcessing(false);
            // no-matter of API response, we need to clean all local data
            getParentOfType(self, StoreS).reset();
            Notifications.success("You successfully logout. Hope to see you soon.");
            navigate("/");
        });
    },
}); });
export default Profile;
