import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import GitHubIconSVG from "media/github-icon.svg";
import GoogleIconSVG from "media/google-icon.svg";
import DocumentTitle from "components/DocumentTitle";
import Notifications from "stores/Notifications";
import Store from "stores/Store";
export var RequestBetaCallback = observer(function () {
    var navigate = useNavigate();
    var provider = useParams().provider;
    var query = useSearchParams()[0];
    var code = query.get("code");
    var state = query.get("state");
    var api = Store.api;
    useEffect(function () {
        if (
        // something is wrong. do nothing
        provider === undefined ||
            code === null ||
            state == null) {
            navigate("/");
        }
    }, [provider, code, state]);
    useEffect(function () {
        if (provider && code) {
            api
                .get("/api/v1/account/request-beta/".concat(provider, "/callback"), { params: { code: code, state: state } })
                .then(function () {
                Notifications.success("Your request has been successfully submitted. We will contact you soon.");
                navigate("/");
            })
                .catch(function () {
                Notifications.error();
            });
        }
        return function () { return Store.Profile.setNotYetRegisteredUser(null); };
    }, []);
    return null;
});
var RedirectToProviderButton = function (_a) {
    var name = _a.name, title = _a.title, providerImg = _a.providerImg;
    var api = Store.api;
    var getUrl = function (provider) {
        if (process.env.NODE_ENV === "development") {
            provider = "".concat(provider, "-local");
        }
        return new Promise(function (resolve) {
            api
                .get("/api/v1/account/request-beta/".concat(provider))
                .then(function (response) {
                resolve(response.data.data);
            })
                .catch(function () { });
        });
    };
    var login = function () {
        getUrl(name).then(function (authURL) {
            window.location.href = authURL;
        });
    };
    return (_jsxs("button", { type: "button", className: "btn-transparent-large", onClick: login, children: [_jsx("img", { src: providerImg, alt: title, className: "social-icon" }), _jsxs("span", { children: ["Continue with ", title] })] }));
};
export default (function () { return (_jsxs(_Fragment, { children: [_jsx(DocumentTitle, { title: "Request beta" }), _jsx("div", { className: "container", children: _jsx("div", { className: "auth-container", children: _jsxs("div", { className: "auth-form", id: "loginForm", children: [_jsx("h2", { className: "title", children: "Sign up to request a beta" }), _jsxs("div", { className: "social-login", children: [_jsx(RedirectToProviderButton, { name: "google", title: "Google", providerImg: GoogleIconSVG }, "google"), _jsx(RedirectToProviderButton, { name: "github", title: "GitHub", providerImg: GitHubIconSVG }, "github")] })] }) }) })] })); });
