import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import GitHubIconSVG from "media/github-icon.svg";
import GoogleIconSVG from "media/google-icon.svg";
import DocumentTitle from "components/DocumentTitle";
import Store from "stores/Store";
var RedirectToProviderButton = function (_a) {
    var name = _a.name, title = _a.title, providerImg = _a.providerImg;
    var login = function () {
        Store.Profile.oauth2.getUrl(name).then(function (authURL) {
            window.location.href = authURL;
        });
    };
    return (_jsxs("button", { type: "button", className: "btn-transparent-large", onClick: login, children: [_jsx("img", { src: providerImg, alt: title, className: "social-icon" }), _jsxs("span", { children: ["Continue with ", title] })] }));
};
export default (function () { return (_jsxs(_Fragment, { children: [_jsx(DocumentTitle, { title: "Sign In" }), _jsx("div", { className: "container", children: _jsx("div", { className: "auth-container", children: _jsxs("div", { className: "auth-form", id: "loginForm", children: [_jsx("h2", { className: "title", children: "Sign In" }), _jsxs("div", { className: "social-login", children: [_jsx(RedirectToProviderButton, { name: "google", title: "Google", providerImg: GoogleIconSVG }, "google"), _jsx(RedirectToProviderButton, { name: "github", title: "GitHub", providerImg: GitHubIconSVG }, "github")] })] }) }) })] })); });
